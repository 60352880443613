import React, { useContext } from "react"
import { Button, Container, FormGroup, FormLabel, FormText, ListGroup, ListGroupItem, Modal } from "react-bootstrap"
import { Async, Operation, Variable } from "react-declarative-state"
import { useNavigate } from "react-router-dom"
import { logout } from "../../services/sessionService"
import Session, { SessionContext } from "../../types/Session"
import {
  createWorkStation,
  getWorkStations,
  removeWorkstation,
  updateWorkstation,
} from "../../services/workStationService"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"
import UsersLists from "./UsersList"
import ReportsList from "./ReportsList"
import { Form } from "react-good-form"
import { FormCheck } from "../../utility/FormComponents"
import { Workstation } from "../../types/Workstation"
import { pick } from "lodash"

const UpsertWorkStationModal: React.FC<{
  onHide: (dirty: boolean) => void
  context: Session
  workStationToEdit?: Workstation
}> = ({ onHide, context, workStationToEdit }) => (
  <Variable
    initialValue={
      workStationToEdit
        ? {
            ...pick(workStationToEdit, [
              "id",
              "name",
              "usesPrograms",
              "customField0Name",
              "customField0Type",
              "customField1Name",
              "customField1Type",
            ]),
            useCustomField0: workStationToEdit.customField0Name !== null,
            useCustomField1: workStationToEdit.customField1Name !== null,
          }
        : {
            id: null,
            name: "",
            usesPrograms: false,
            useCustomField0: false,
            customField0Name: null,
            customField0Type: null,
            useCustomField1: false,
            customField1Name: null,
            customField1Type: null,
          }
    }
  >
    {(newWorkStation, setNewWorkStation) => (
      <Operation
        onDone={() => {
          onHide(true)
        }}
      >
        {(doOperation, progress) => (
          <Modal show onHide={() => onHide(false)}>
            <Modal.Header closeButton>
              {workStationToEdit ? (
                <div className="d-flex flex-column">
                  <Modal.Title>Muokkaa työpistettä </Modal.Title>
                  <div className="small">{workStationToEdit.name}</div>
                </div>
              ) : (
                <Modal.Title>Lisää työpiste</Modal.Title>
              )}
            </Modal.Header>
            <Form
              value={newWorkStation}
              onChange={setNewWorkStation}
              onSubmit={() => {
                const {
                  id,
                  useCustomField0,
                  useCustomField1,
                  customField0Name,
                  customField0Type,
                  customField1Name,
                  customField1Type,
                  ...rest
                } = newWorkStation

                const data = {
                  ...rest,
                  customField0Name: (useCustomField0 && customField0Name) || null,
                  customField0Type: (useCustomField0 && customField0Type) || null,
                  customField1Name: (useCustomField1 && customField1Name) || null,
                  customField1Type: (useCustomField1 && customField1Type) || null,
                }

                doOperation(id ? updateWorkstation(context, data, id) : createWorkStation(context, data))
              }}
            >
              {({ Input, Select }) => (
                <>
                  <Modal.Body>
                    <FormGroup className="mb-3">
                      <FormLabel>Työpisteen nimi:</FormLabel>
                      <Input className="form-control" required minLength={2} for="name"></Input>
                    </FormGroup>

                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <hr className="flex-fill" />
                      <div className="text-muted small mx-2">Työpisteen asetukset</div>
                      <hr className="flex-fill" />
                    </div>
                    <FormGroup className="mb-3">
                      <FormCheck
                        label="Merkitse ajo-ohjelma"
                        checked={newWorkStation.usesPrograms}
                        onClick={() => {
                          setNewWorkStation({ ...newWorkStation, usesPrograms: !newWorkStation.usesPrograms })
                        }}
                      />
                    </FormGroup>
                    <div className="mb-3">
                      <FormText>
                        Kaikissa työpisteissä mitattavina arvoina on tuotteen tunnus/koodi, nosto, uusintatyö,
                        ajo-ohjelma sekä kappalemäärä. Voit lisätä kaksi vapaavalintaista seurattavaa arvoa tarpeen
                        mukaan.
                      </FormText>
                    </div>
                    <FormGroup className="mb-3 border rounded p-3">
                      <FormCheck
                        className="pb-3 pt-2"
                        onClick={() =>
                          setNewWorkStation({ ...newWorkStation, useCustomField0: !newWorkStation.useCustomField0 })
                        }
                        checked={newWorkStation.useCustomField0}
                        label="Käytä mukautettavaa mitattavaa arvoa 1"
                      />
                      <FormGroup className="mb-3">
                        <FormLabel>Mitattavan arvon nimi</FormLabel>
                        <Input
                          disabled={!newWorkStation.useCustomField0}
                          className="form-control"
                          for="customField0Name"
                          required={newWorkStation.useCustomField0}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <FormLabel>Mitattavan arvon tyyppi</FormLabel>
                        <Select
                          disabled={!newWorkStation.useCustomField0}
                          className="form-control"
                          for="customField0Type"
                          required={newWorkStation.useCustomField0}
                        >
                          <option value="">Valitse</option>
                          <option value="text">Teksti</option>
                          <option value="number">Numero</option>
                        </Select>
                      </FormGroup>
                    </FormGroup>
                    <FormGroup className="mb-3 border rounded p-3">
                      <FormCheck
                        className="pb-3 pt-2"
                        onClick={() =>
                          setNewWorkStation({ ...newWorkStation, useCustomField1: !newWorkStation.useCustomField1 })
                        }
                        checked={newWorkStation.useCustomField1}
                        label="Käytä mukautettavaa mitattavaa arvoa 2"
                      />
                      <FormLabel>Mitattavan arvon nimi</FormLabel>
                      <Input
                        disabled={!newWorkStation.useCustomField1}
                        className="form-control mb-3"
                        for="customField1Name"
                        required={newWorkStation.useCustomField1}
                      />
                      <FormLabel>Mitattavan arvon tyyppi</FormLabel>
                      <Select
                        disabled={!newWorkStation.useCustomField1}
                        className="form-control"
                        for="customField1Type"
                        required={newWorkStation.useCustomField1}
                      >
                        <option value="">Valitse</option>
                        <option value="text">Teksti</option>
                        <option value="number">Numero</option>
                      </Select>
                    </FormGroup>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button disabled={progress === Async.Progress.Progressing} type="submit">
                      {workStationToEdit ? "Tallenna" : "Lisää"}
                    </Button>
                  </Modal.Footer>
                </>
              )}
            </Form>
          </Modal>
        )}
      </Operation>
    )}
  </Variable>
)

const ProfilePage: React.FC<{ onLogout: () => void }> = ({ onLogout }) => {
  const navigate = useNavigate()
  const context = useContext(SessionContext)
  return (
    <Container>
      <Operation
        onDone={() => {
          onLogout()
          navigate("/")
        }}
      >
        {(dOperation) => (
          <div className="d-flex flex-column py-4">
            <div className="d-flex shadow-sm justify-content-between align-items-center bg-light border rounded p-4">
              Olet kirjautunut käyttäjänä {context.name}
              <Button
                onClick={() => {
                  dOperation(logout())
                }}
              >
                Kirjaudu ulos
              </Button>
            </div>
          </div>
        )}
      </Operation>
      {context.role === "companyAdmin" && (
        <Variable initialValue={getWorkStations(context)}>
          {(workStations, setWorkStations) => (
            <>
              <div className="d-flex flex-column pb-4">
                <div className="d-flex flex-column shadow-sm bg-light border rounded p-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4>Työpisteet</h4>
                    <Variable initialValue={false}>
                      {(modalOpen, setModalOpen) => (
                        <>
                          <Button
                            size="sm"
                            variant="light"
                            onClick={() => {
                              setModalOpen(true)
                            }}
                          >
                            <FontAwesomeIcon icon={faPlus} className="me-2" size="sm" />
                            Lisää työpiste
                          </Button>
                          {modalOpen && (
                            <UpsertWorkStationModal
                              context={context}
                              onHide={(dirty) => {
                                setModalOpen(false)
                                if (dirty) {
                                  setWorkStations(getWorkStations(context))
                                }
                              }}
                            />
                          )}
                        </>
                      )}
                    </Variable>
                  </div>
                  {workStations.length === 0 && (
                    <div className="d-flex justify-content-center align-items-center p-5 text-center text-muted">
                      Et ole vielä luonut yhtään työpistettä. Voit aloittaa klikkaamalla "Lisää työpiste" painiketta.
                    </div>
                  )}
                  <Operation onDone={() => setWorkStations(getWorkStations(context))}>
                    {(doOperation, progress) => (
                      <ListGroup className="mt-3">
                        {workStations.map((workStation) => (
                          <ListGroupItem
                            className="d-flex justify-content-between align-items-center"
                            key={workStation.id}
                          >
                            <div style={{ minHeight: 31 }}>{workStation.name}</div>
                            <div>
                              <Variable initialValue={false}>
                                {(modalOpen, setModalOpen) => (
                                  <>
                                    <Button
                                      size="sm"
                                      variant="primary"
                                      className="me-1"
                                      onClick={() => {
                                        setModalOpen(true)
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                    {modalOpen && (
                                      <UpsertWorkStationModal
                                        context={context}
                                        workStationToEdit={workStation}
                                        onHide={(dirty) => {
                                          setModalOpen(false)
                                          if (dirty) {
                                            setWorkStations(getWorkStations(context))
                                          }
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                              </Variable>
                              <Button
                                size="sm"
                                disabled={progress === Async.Progress.Progressing}
                                variant={"danger"}
                                onClick={() => doOperation(removeWorkstation(context, workStation.id))}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </div>
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    )}
                  </Operation>
                </div>
              </div>
            </>
          )}
        </Variable>
      )}
      <UsersLists />
      <ReportsList />
    </Container>
  )
}

export default ProfilePage
