import React from "react"
import { Alert, Button, Container, Form, FormLabel } from "react-bootstrap"
import { Async, Operation, Variable } from "react-declarative-state"
import { Form as GoodForm } from "react-good-form"
import { useNavigate } from "react-router-dom"
import { NewAccount, registerNewUser } from "../services/userService"
import Session from "../types/Session"

interface RegisterProps {
  onRegister: (session: Session) => void
}

const renderWarning = (message: String) => <div className="text-danger">{message}</div>

const Register: React.FC<RegisterProps> = ({ onRegister }) => {
  const navigate = useNavigate()
  return (
    <Variable
      initialValue={
        { companyName: "", firstName: "", lastName: "", email: "", password: "", confirmPassword: "" } as NewAccount
      }
    >
      {(newAccount, setNewAccount) => {
        const hasPassword = () => newAccount.password.length > 4
        const passwordsMatch = () => newAccount.password === newAccount.confirmPassword
        const isEmail = () => {
          const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
          return emailRegex.test(newAccount.email)
        }
        return (
          <Container>
            <Operation
              onDone={({ session }: { session: Session }) => {
                onRegister(session)
                localStorage.setItem("sessionToken", session.token || "")
                navigate("/")
              }}
            >
              {(doOperation, progress, error) => (
                <GoodForm value={newAccount} onChange={setNewAccount}>
                  {({ Input }, { touched, invalid }) => {
                    const showError = (field: keyof NewAccount) => touched(field) && !!invalid(field)
                    const getInputClass = (error: boolean) => `form-control ${error ? "border-danger" : ""}`
                    return (
                      <>
                        {error === "409" && (
                          <Alert variant="info" className="text-center">
                            Tällä sähköpostiosoitteella on luotu jo tili.{" "}
                            <Button
                              variant="link"
                              onClick={() => {
                                navigate("/app/request-password")
                              }}
                            >
                              Pyydä uusi salasana
                            </Button>
                          </Alert>
                        )}
                        <Form.Group className="mb-3">
                          <FormLabel>Yrityksen nimi</FormLabel>
                          <Input
                            minLength={2}
                            required
                            className={getInputClass(showError("companyName"))}
                            type="text"
                            for="companyName"
                          />
                          {showError("companyName") && renderWarning("Yrityksen on liian lyhyt")}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <FormLabel>Etunimi</FormLabel>
                          <Input
                            minLength={2}
                            required
                            className={getInputClass(showError("firstName"))}
                            type="text"
                            for="firstName"
                          />
                          {showError("firstName") && renderWarning("Etunimi on liian lyhyt")}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <FormLabel>Sukunimi</FormLabel>
                          <Input
                            minLength={2}
                            required
                            className={getInputClass(showError("lastName"))}
                            type="text"
                            for="lastName"
                          />
                          {showError("lastName") && renderWarning("Sukunimi on liian lyhyt")}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <FormLabel>Sähköposti</FormLabel>
                          <Input
                            rule={isEmail}
                            required
                            className={getInputClass(showError("email"))}
                            type="email"
                            for="email"
                          />
                          {showError("email") && renderWarning("Sähköpostiosoitteessa on jotain pielessä")}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <FormLabel>Salasana</FormLabel>
                          <Input
                            rule={hasPassword}
                            required
                            className={getInputClass(showError("confirmPassword"))}
                            autoComplete="new-password"
                            type="password"
                            for="password"
                          />
                          {showError("password") && renderWarning("Salasana on liian lyhyt")}
                        </Form.Group>
                        <Form.Group className="mb-4">
                          <FormLabel>Salasana uudestaan</FormLabel>
                          <Input
                            type="password"
                            autoComplete="new-password"
                            required
                            className={getInputClass(showError("confirmPassword"))}
                            for="confirmPassword"
                            rule={passwordsMatch}
                          />
                          {showError("confirmPassword") && renderWarning("Salasanat eivät vastaaa toisiaan")}
                        </Form.Group>
                        <Form.Group>
                          <Button
                            variant="primary"
                            value="submit"
                            className="form-control"
                            disabled={
                              progress === Async.Progress.Progressing ||
                              !!Object.keys(newAccount).some((key) => !!invalid(key as keyof NewAccount))
                            }
                            onClick={() => {
                              const { confirmPassword, ...account } = newAccount
                              doOperation(registerNewUser(account))
                            }}
                          >
                            Rekisteröidy
                          </Button>
                        </Form.Group>
                      </>
                    )
                  }}
                </GoodForm>
              )}
            </Operation>
          </Container>
        )
      }}
    </Variable>
  )
}

export default Register
