import React from "react"
import MobileUtilityPageWrapper from "../../page_wrappers/MobileUtilityPageWrapper"
import RequestPasswordLink from "../../views/RequestPasswordLink"

const RequestPasswordLinkPage: React.FC = () => {
  return (
    <MobileUtilityPageWrapper title={""} footer={<></>}>
      <RequestPasswordLink />
    </MobileUtilityPageWrapper>
  )
}

export default RequestPasswordLinkPage
