import React from "react"
import { Form as GoodForm } from "react-good-form"
import { Variable } from "react-declarative-state"
import { FormGroup, Form, Button, Container } from "react-bootstrap"

const RequestPasswordLink: React.FC = () => {
  return (
    <Container>
      <Variable initialValue={{ email: "", password: "" } as { email: string; password: string }}>
        {(credentials, setCredentials) => (
          <GoodForm value={credentials} onChange={setCredentials}>
            {({ Input }) => (
              <>
                <div className="d-flex flex-column">
                  <h1>Unohditko salasanasi?</h1>
                  <div>
                    Anna sähköpostiosoitteesi, jota olet käyttänyt tilin luomiseen, Lähetämme sinulle linkin salasanan
                    vaihtamista varten.
                  </div>
                  <FormGroup className="mb-3">
                    <Form.Label>Sähköposti</Form.Label>
                    <Input type="email" for="email" required className="form-control"></Input>
                  </FormGroup>
                  <div className="mb-4">
                    <Button className="form-control" type="submit" variant="primary">
                      Lähetä linkki
                    </Button>
                  </div>
                </div>
              </>
            )}
          </GoodForm>
        )}
      </Variable>
    </Container>
  )
}

export default RequestPasswordLink
