import { GET, POST, PUT } from "../utility/serviceUtility"
import { ReportLink } from "../types/ReportLink"
import Session from "../types/Session"

export function getReports(session: Session) {
  return GET<ReportLink[]>(`/reports/${session.companyId}/all`)
}

export function createReportLink() {
  return POST<ReportLink>("/reports/create", {} as any)
}

export function voidReportLinkById(reportLinkId: string) {
  return PUT<ReportLink>(`/reports/${reportLinkId}/void`, {} as any)
}
