import react, { useContext, useRef } from "react"
import _ from "lodash"
import Session, { SessionContext } from "../../types/Session"
import { Async, Constant, Operation, Variable } from "react-declarative-state"
import {
  Alert,
  Button,
  Col,
  Collapse,
  FormControl,
  FormGroup,
  FormLabel,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap"
import {
  createWorkStationTimeStamp,
  getWorkStationTimeStamps,
  getWorkStations,
} from "../../services/workStationService"
import { toast } from "../../portals/toast"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExchange, faUser, faCommentDots } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
import { Workstation as WorkstationType } from "../../types/Workstation"
import { FormCheck } from "../../utility/FormComponents"
import React from "react"
import TimestampsTable from "../../components/TimestampsTable"

export const AddComment: React.FC<{ workStationId: string; context: Session }> = ({ workStationId, context }) => {
  console.log(workStationId)
  const initialComment = { comment: "", editing: false }
  return (
    <Variable initialValue={initialComment}>
      {(newComment, setNewComment) => {
        const { editing } = newComment
        return (
          <div
            style={{ transition: "all 300ms" }}
            className={`d-flex flex-column bg-light rounded border ${editing ? "p-4" : "p-3"}`}
          >
            <div className="d-flex justify-content-between align-items-center">
              <FormLabel className="mb-0">Kerro ongelmatilanteesta linjalla</FormLabel>

              {!editing && (
                <Button
                  size="sm"
                  variant={editing ? "light" : "primary"}
                  onClick={() => setNewComment({ comment: "", editing: !editing })}
                >
                  <>
                    <FontAwesomeIcon icon={faCommentDots} className="me-2" />
                    Lisää kommentti
                  </>
                </Button>
              )}
            </div>
            <Collapse in={editing}>
              <div className="mt-3">
                <FormControl
                  className="mb-1"
                  placeholder="Kirjoita kommentti..."
                  style={{ background: "transparent", resize: "none" }}
                  as="textarea"
                  value={newComment.comment}
                  rows={5}
                  onChange={(e: any) => setNewComment({ ...newComment, comment: e.target.value })}
                ></FormControl>
                <div className="d-flex small text-muted">Kommentoit käyttäjänä {context.name}</div>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="light"
                    className="me-2"
                    onClick={() => {
                      setNewComment(initialComment)
                    }}
                  >
                    Peruuta
                  </Button>
                  <Button variant="primary">Lähetä</Button>
                </div>
              </div>
            </Collapse>
          </div>
        )
      }}
    </Variable>
  )
}

const SHOW_ITEMS = 20

const Workstation: react.FC = () => {
  const context = useContext(SessionContext)
  const startInputRef = useRef<any>()
  const emptyTimeStamp = {
    toolNumber: "",
    batchNumber: "",
    batchSize: 10,
    isReWork: false,
    customField0: null,
    customField1: null,
    program: null,
  }
  return (
    <Constant value={getWorkStations(context)}>
      {(workStations) => (
        <>
          {workStations.length === 0 ? (
            <div className="mt-5">
              <Alert variant="info">
                Et ole vielä määrittänyt yhtään työpistettä. Aloita lisäämällä työpiste{" "}
                <Link to={"/profile"} className="fontweight-bold">
                  täällä
                </Link>
              </Alert>
            </div>
          ) : (
            <Variable initialValue={"none" as WorkstationType | "none"}>
              {(selectedWorkStation, setSelectedWorkStation) => (
                <>
                  {selectedWorkStation === "none" && (
                    <Row>
                      <Col xs={12} md={{ span: 6, offset: 3 }}>
                        <div
                          className="my-4 border rounded p-4
                    "
                        >
                          <div className="d-flex justify-content-center text-center px-4 mb-3">
                            <h5>Aloita kirjaaminen valitsemalla työpiste</h5>
                          </div>
                          <div className="d-flex justify-content-center align-items-center mb-3 text-muted">
                            <FontAwesomeIcon icon={faUser} className="me-2" /> {context.name}
                          </div>
                          <ListGroup className="d-flex">
                            {workStations.map((workStation) => (
                              <ListGroupItem
                                action
                                key={workStation.id}
                                onClick={() => setSelectedWorkStation(workStation)}
                              >
                                {workStation.name}
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {selectedWorkStation !== "none" && (
                    <>
                      <div className="d-flex justify-content-between my-3 align-items-center">
                        <h4 className="m-0">{selectedWorkStation.name}</h4>
                        <Button
                          variant="light"
                          onClick={() => {
                            setSelectedWorkStation("none")
                          }}
                        >
                          <FontAwesomeIcon icon={faExchange} className="mr-2" /> Vaihda työpistettä
                        </Button>
                      </div>
                      <Variable
                        key={selectedWorkStation.id}
                        initialValue={getWorkStationTimeStamps(context, selectedWorkStation.id, SHOW_ITEMS)}
                      >
                        {(timestamps, setTimestamps) => {
                          return (
                            <>
                              <Variable initialValue={emptyTimeStamp}>
                                {(newTimeStamp, setNewTimeStamp) => {
                                  const timeStampIsStorable = !!newTimeStamp.batchNumber && !!newTimeStamp.batchSize
                                  return (
                                    <Operation onDone={() => toast("Tapahtuma rekisterlity", "success")}>
                                      {(doOperation, progress) => {
                                        const handleAddTimeStamp = () => {
                                          doOperation(
                                            createWorkStationTimeStamp(context, selectedWorkStation.id, {
                                              ..._.pick(newTimeStamp, [
                                                "batchNumber",
                                                "batchSize",
                                                "toolNumber",
                                                "isReWork",
                                                "customField0",
                                                "customField1",
                                                "program",
                                              ]),
                                              createdAt: new Date().toISOString(),
                                            }).do(
                                              (createdTimeStamp) => {
                                                setTimestamps([...timestamps, createdTimeStamp])
                                              },
                                              () => {
                                                toast("Tapahtuman rekisteröinti epäonnistui", "danger")
                                              }
                                            )
                                          )
                                          setNewTimeStamp(emptyTimeStamp)
                                          startInputRef.current?.focus()
                                        }
                                        return (
                                          <>
                                            <div className="bg-light border d-flex flex-column flex-md-row shadow-sm mb-4 justify-content-between p-4 pt-3 rounded align-items-end mt-3">
                                              <div className="flex-fill">
                                                <Row>
                                                  <Col md={3}>
                                                    <FormGroup className="mx-1">
                                                      <FormLabel>Tuote:</FormLabel>
                                                      <FormControl
                                                        ref={startInputRef}
                                                        autoFocus
                                                        type="text"
                                                        value={newTimeStamp.batchNumber}
                                                        onChange={(e: any) =>
                                                          setNewTimeStamp({
                                                            ...newTimeStamp,
                                                            batchNumber: e.target.value,
                                                          })
                                                        }
                                                      ></FormControl>
                                                    </FormGroup>
                                                  </Col>
                                                  <Col md={3}>
                                                    <FormGroup className="mx-1">
                                                      <FormLabel>Nosto:</FormLabel>
                                                      <FormControl
                                                        type="text"
                                                        value={newTimeStamp.toolNumber}
                                                        onChange={(e: any) =>
                                                          setNewTimeStamp({
                                                            ...newTimeStamp,
                                                            toolNumber: e.target.value,
                                                          })
                                                        }
                                                      ></FormControl>
                                                    </FormGroup>
                                                  </Col>
                                                  <Col md={3}>
                                                    <FormGroup className="mx-1">
                                                      <FormLabel>Määrä:</FormLabel>
                                                      <FormControl
                                                        onKeyDown={(e) => {
                                                          if (e.code === "Enter") {
                                                            handleAddTimeStamp()
                                                          }
                                                        }}
                                                        type="number"
                                                        value={newTimeStamp.batchSize}
                                                        min={0}
                                                        onChange={(e: any) =>
                                                          setNewTimeStamp({
                                                            ...newTimeStamp,
                                                            batchSize: parseInt(e.target.value, 10),
                                                          })
                                                        }
                                                      ></FormControl>
                                                    </FormGroup>
                                                  </Col>
                                                  <Col md={3} className="align-self-end">
                                                    <FormCheck
                                                      className="mb-2"
                                                      checked={newTimeStamp.isReWork}
                                                      onClick={() => {
                                                        setNewTimeStamp({
                                                          ...newTimeStamp,
                                                          isReWork: !newTimeStamp.isReWork,
                                                        })
                                                      }}
                                                      label="Korjaustyö"
                                                    />
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  {!!selectedWorkStation.usesPrograms && (
                                                    <Col md={3}>
                                                      <FormGroup className="mx-1 mt-2">
                                                        <FormLabel>Ohjelma</FormLabel>
                                                        <FormControl
                                                          onKeyDown={(e) => {
                                                            if (e.code === "Enter") {
                                                              handleAddTimeStamp()
                                                            }
                                                          }}
                                                          type={"text"}
                                                          value={newTimeStamp.program || ""}
                                                          onChange={(e: any) =>
                                                            setNewTimeStamp({
                                                              ...newTimeStamp,
                                                              program: e.target.value,
                                                            })
                                                          }
                                                        ></FormControl>
                                                      </FormGroup>
                                                    </Col>
                                                  )}
                                                  {!!selectedWorkStation.customField0Name && (
                                                    <Col md={3}>
                                                      <FormGroup className="mx-1 mt-2">
                                                        <FormLabel>{selectedWorkStation.customField0Name}</FormLabel>
                                                        <FormControl
                                                          onKeyDown={(e) => {
                                                            if (e.code === "Enter") {
                                                              handleAddTimeStamp()
                                                            }
                                                          }}
                                                          type={
                                                            selectedWorkStation.customField0Type === "number"
                                                              ? "number"
                                                              : "text"
                                                          }
                                                          value={newTimeStamp.customField0 || ""}
                                                          onChange={(e: any) =>
                                                            setNewTimeStamp({
                                                              ...newTimeStamp,
                                                              customField0: e.target.value,
                                                            })
                                                          }
                                                        ></FormControl>
                                                      </FormGroup>
                                                    </Col>
                                                  )}
                                                  {!!selectedWorkStation.customField1Name && (
                                                    <Col md={3}>
                                                      <FormGroup className="mx-1 mt-2">
                                                        <FormLabel>{selectedWorkStation.customField1Name}</FormLabel>
                                                        <FormControl
                                                          onKeyDown={(e) => {
                                                            if (e.code === "Enter") {
                                                              handleAddTimeStamp()
                                                            }
                                                          }}
                                                          type={
                                                            selectedWorkStation.customField1Type === "number"
                                                              ? "number"
                                                              : "text"
                                                          }
                                                          value={newTimeStamp.customField1 || ""}
                                                          onChange={(e: any) =>
                                                            setNewTimeStamp({
                                                              ...newTimeStamp,
                                                              customField1: e.target.value,
                                                            })
                                                          }
                                                        ></FormControl>
                                                      </FormGroup>
                                                    </Col>
                                                  )}
                                                </Row>
                                              </div>
                                              <div className="d-flex">
                                                <Button
                                                  variant="success"
                                                  className="formcontrol mx-2"
                                                  disabled={
                                                    !timeStampIsStorable || progress === Async.Progress.Progressing
                                                  }
                                                  onClick={() => handleAddTimeStamp()}
                                                >
                                                  Lisää
                                                </Button>
                                              </div>
                                            </div>
                                          </>
                                        )
                                      }}
                                    </Operation>
                                  )
                                }}
                              </Variable>
                              {!!timestamps.length && (
                                <div className="d-flex justify-content-between align-items-center">
                                  <hr className="flex-fill" />
                                  <div className="mx-2 small text-muted">
                                    Näytetään {timestamps.length < SHOW_ITEMS ? timestamps.length : SHOW_ITEMS}{" "}
                                    viimeisintä kirjausta
                                  </div>
                                  <hr className="flex-fill" />
                                </div>
                              )}
                              <TimestampsTable
                                timestamps={timestamps}
                                setTimestamps={setTimestamps}
                                selectedWorkStation={selectedWorkStation}
                              />
                            </>
                          )
                        }}
                      </Variable>
                    </>
                  )}
                </>
              )}
            </Variable>
          )}
        </>
      )}
    </Constant>
  )
}

export default Workstation
