import react from "react"
import { Container } from "react-bootstrap"
import Workstation from "../views/Workstation/Workstation"

const WorkstationPage: react.FC = () => (
  <Container>
    <Workstation />
  </Container>
)

export default WorkstationPage
