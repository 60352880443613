import Session from "../types/Session"
import { Workstation } from "../types/Workstation"
import { WorkstationTimestamp } from "../types/WorkstationTimestamp"
import { GET, POST, PUT } from "../utility/serviceUtility"

interface UpsertWorkStationBody {
  name: string
  customField0Name: string | null
  customField1Name: string | null
  customField0Type: string | null
  customField1Type: string | null
  usesPrograms: boolean
}

export const createWorkStation = (user: Session, newWorkStation: UpsertWorkStationBody) =>
  POST(`/workstation/${user.companyId}/create`, newWorkStation)
export const createWorkStationTimeStamp = (
  user: Session,
  workStationId: string,
  timeStamp: {
    batchNumber: string
    toolNumber?: string
    batchSize: number
    createdAt: string
    program: string | null
    customField0: string | null
    customField1: string | null
  }
) =>
  POST<WorkstationTimestamp>(
    `/workstation/${workStationId}/timestamp/company/${user.companyId}/user/${user.userId}`,
    timeStamp as WorkstationTimestamp
  )

export const getWorkStations = (user: Session) => GET<Workstation[]>(`/workstation/${user.companyId}/all`)

export type TimestampSearchFilter = {
  workStationId?: string
  start?: string
  end?: string
  batchNumber?: string
  toolNumber?: string
}

export const timestampsSearch = (user: Session, filters: TimestampSearchFilter) => {
  const query = new URLSearchParams(filters)
  return GET<WorkstationTimestamp[]>(`/workstation/${user.companyId}/search?${query.toString()}`)
}

export const getWorkStationTimeStamps = (user: Session, workStationId: string, shownAmount: number) =>
  GET<WorkstationTimestamp[]>(
    `/workstation/${workStationId}/timestamp/company/${user.companyId}?limit=${shownAmount}&offset=0`
  )

export const updateWorkstation = (user: Session, data: UpsertWorkStationBody, workStationId: string) =>
  PUT<Partial<Workstation>>(`/workstation/${user.companyId}/update/${workStationId}`, data).map(
    (workStation) => workStation as Workstation
  )
export const editWorkstationTimeStamp = (
  user: Session,
  timeStampId: string,
  data: {
    batchNumber: string
    toolNumber?: string
    batchSize: number
    isReWork: boolean
    customField0: string | null
    customField1: string | null
    program: string | null
  }
) => PUT<Partial<WorkstationTimestamp>>(`/workstation/timestamp/${timeStampId}/company/${user.companyId}/edit`, data)

export const voidWorkstationTimeStamp = (user: Session, timeStampId: string) =>
  PUT<Partial<WorkstationTimestamp>>(`/workstation/timestamp/${timeStampId}/company/${user.companyId}/void`, {})

export const removeWorkstation = (user: Session, workStationId: string) =>
  PUT(`/workstation/${user.companyId}/remove/${workStationId}`, {})
