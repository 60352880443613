import React, { useContext } from "react"
import { Alert, Container, FormControl, FormGroup, FormLabel } from "react-bootstrap"
import { Async, Constant, Controlled, Variable } from "react-declarative-state"
import TimestampsTable from "../../components/TimestampsTable"
import moment from "moment"
import { getWorkStations, timestampsSearch } from "../../services/workStationService"
import { SessionContext } from "../../types/Session"
import { Link } from "react-router-dom"
import { Observable } from "rxjs"
import { WorkstationTimestamp } from "../../types/WorkstationTimestamp"

const TimestampFixer: React.FC = () => {
  const context = useContext(SessionContext)
  return (
    <Container className="py-3">
      <Constant value={getWorkStations(context)}>
        {(workStations) => {
          if (!workStations.length) {
            return (
              <div className="mt-5">
                <Alert variant="info">
                  Et ole vielä määrittänyt yhtään työpistettä. Aloita lisäämällä työpiste{" "}
                  <Link to={"/profile"} className="fontweight-bold">
                    täällä
                  </Link>
                </Alert>
              </div>
            )
          }
          return (
            <Variable
              initialValue={{
                start: "",
                end: "",
                batchNumber: "",
                toolNumber: "",
                updatedAt: moment().toISOString(),
              }}
            >
              {(filters, setFilters) => (
                <Variable initialValue={workStations[0]}>
                  {(selectedWorkstation, setSelectedWorkStation) => {
                    const shouldSearch = filters.batchNumber.length || filters.toolNumber.length
                    return (
                      <>
                        <div className="d-flex justify-content-start my-3">
                          <h4>Hae tapahtumaa</h4>
                        </div>
                        <div className="d-flex justify-content-between mb-4 p-4 bg-light rounded border shadow-sm">
                          <FormGroup style={{ width: 200 }}>
                            <FormLabel>Työpiste</FormLabel>
                            <FormControl
                              as="select"
                              value={selectedWorkstation.id}
                              onChange={(e: any) => {
                                const newSelectedWorkStation = workStations.find(({ id }) => e.target.value === id)
                                if (!newSelectedWorkStation) throw new Error("Workstation not found")
                                setSelectedWorkStation(newSelectedWorkStation)
                              }}
                            >
                              {workStations.map((ws) => (
                                <option key={ws.id} value={ws.id}>
                                  {ws.name}
                                </option>
                              ))}
                            </FormControl>
                          </FormGroup>
                          <div className="d-flex">
                            <FormGroup className="me-3" style={{ width: 200 }}>
                              <FormLabel>Tuote</FormLabel>
                              <FormControl
                                value={filters.batchNumber}
                                onChange={(e: any) => setFilters({ ...filters, batchNumber: e.target.value })}
                              />
                            </FormGroup>
                            <FormGroup style={{ width: 200 }}>
                              <FormLabel>Nosto</FormLabel>
                              <FormControl
                                value={filters.toolNumber}
                                onChange={(e: any) => setFilters({ ...filters, toolNumber: e.target.value })}
                              />
                            </FormGroup>
                          </div>
                        </div>
                        <Controlled
                          controlKey={JSON.stringify({ ...filters, workStation: selectedWorkstation.id })}
                          debounceTime={300}
                          value={
                            (shouldSearch
                              ? timestampsSearch(context, { ...filters, workStationId: selectedWorkstation.id })
                              : Observable.of("no_search")) as Observable<WorkstationTimestamp[] | "no_search">
                          }
                        >
                          {(results, progress) =>
                            results === "no_search" ? (
                              <div className="d-flex align-items-center text-muted justify-content-center p-4">
                                {`Hae tapahtumaa "Tuote" ja/tai "Nosto" perusteella`}
                              </div>
                            ) : (
                              <>
                                {results.length === 0 && (
                                  <div className="d-flex align-items-center text-muted justify-content-center p-4">
                                    Ei tuloksia
                                  </div>
                                )}
                                <div style={{ opacity: progress === Async.Progress.Progressing ? 0.5 : 1 }}>
                                  <TimestampsTable
                                    selectedWorkStation={selectedWorkstation}
                                    timestamps={results}
                                    setTimestamps={() => {
                                      setFilters({ ...filters, updatedAt: moment().toISOString() })
                                    }}
                                  />
                                </div>
                              </>
                            )
                          }
                        </Controlled>
                      </>
                    )
                  }}
                </Variable>
              )}
            </Variable>
          )
        }}
      </Constant>
    </Container>
  )
}

export default TimestampFixer
