import React from "react"
import MobileUtilityPageWrapper from "../../page_wrappers/MobileUtilityPageWrapper"
import Session from "../../types/Session"
import Login from "../../views/Login"

const LoginPage: React.FC<{ onLogin: (value: Session) => void }> = ({ onLogin }) => {
  return (
    <MobileUtilityPageWrapper title={"Kirjaudu sisään"} footer={<></>}>
      <Login onLogin={onLogin} />
    </MobileUtilityPageWrapper>
  )
}

export default LoginPage
