import React, { useContext, useState } from "react"
import { Async, Operation, Variable } from "react-declarative-state"
import { CreateUser, UpdateUser, getAllUsers, removeUser, upsertUser } from "../../services/userService"
import Session, { SessionContext } from "../../types/Session"
import { Alert, Button, Form, FormControl, FormLabel, ListGroup, ListGroupItem, Modal } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faTrash, faCrown, faPlus } from "@fortawesome/free-solid-svg-icons"
import { User } from "../../types/User"
import { Form as GoodForm } from "react-good-form"
import _ from "lodash"
import { isEmail } from "../../utility/userHelpers"
import { toast } from "../../portals/toast"

const renderWarning = (message: String) => <div className="text-danger">{message}</div>

const AddUserModal: React.FC<{ user?: User; onHide: (user: User | null) => void; context: Session }> = ({
  onHide,
  user: existingUser,
  context,
}) => {
  return (
    <Operation onDone={(user: User) => onHide(user)}>
      {(doOperation, progress) => (
        <Modal show onHide={() => onHide(null)}>
          <div>
            <Modal.Header closeButton>Käyttäjä</Modal.Header>
            <Variable
              initialValue={
                existingUser
                  ? {
                      ..._.pick(existingUser, ["id", "name", "email", "phone", "role"]),
                      password: "",
                      confirmPassword: "",
                    }
                  : {
                      id: null,
                      name: "",
                      email: "",
                      phone: "",
                      password: "",
                      confirmPassword: "",
                      role: "operator" as User["role"],
                    }
              }
            >
              {(newAccount, setNewAccount) => {
                const getInputClass = (error: boolean) => `form-control ${error ? "border-danger" : ""}`
                const passwordsMatch = () => newAccount.password === newAccount.confirmPassword
                const hasPassword = () => ("password" in newAccount && !!newAccount.password) || true
                const isEmailCorrect = () => {
                  return isEmail(newAccount.email)
                }
                return (
                  <>
                    <Modal.Body>
                      <GoodForm value={newAccount} onChange={setNewAccount}>
                        {({ Input }, { touched, invalid }) => {
                          const showError = (field: keyof typeof newAccount) => touched(field) && !!invalid(field)
                          return (
                            <>
                              <Form.Group className="mb-3">
                                <FormLabel>Nimi</FormLabel>
                                <Input
                                  minLength={2}
                                  required
                                  className={getInputClass(showError("name"))}
                                  type="text"
                                  for="name"
                                />
                                {showError("name") && renderWarning("Nimi on liian lyhyt")}
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <FormLabel>Sähköposti</FormLabel>
                                <Input
                                  rule={isEmailCorrect}
                                  required
                                  className={getInputClass(showError("email"))}
                                  type="email"
                                  for="email"
                                />
                                {showError("email") && renderWarning("Sähköpostiosoitteessa on jotain pielessä")}
                              </Form.Group>
                              {!newAccount.id && "password" in newAccount && (
                                <>
                                  <Form.Group className="mb-3">
                                    <FormLabel>Salasana</FormLabel>
                                    <Input
                                      rule={hasPassword}
                                      required
                                      className={getInputClass(showError("password"))}
                                      autoComplete="new-password"
                                      type="password"
                                      for="password"
                                    />
                                    {showError("password") && renderWarning("Salasana on liian lyhyt")}
                                  </Form.Group>
                                  <Form.Group className="mb-4">
                                    <FormLabel>Salasana uudestaan</FormLabel>
                                    <Input
                                      type="password"
                                      autoComplete="new-password"
                                      required
                                      className={getInputClass(showError("password"))}
                                      for="confirmPassword"
                                      rule={passwordsMatch}
                                    />
                                    {showError("password") && renderWarning("Salasanat eivät vastaaa toisiaan")}
                                  </Form.Group>
                                </>
                              )}
                              <Form.Group className="mb-3">
                                <FormLabel>Käyttäjätaso</FormLabel>
                                <Alert variant="info" className="d-flex flex-column small">
                                  <div className="mb-2">
                                    <strong>Pääkäyttäjällä</strong> on oikeus muuttaa kaikkia tietoja palvelussa sekä
                                    oikeus ladata työpisteille rekisteröityjä tapahtumia.
                                  </div>
                                  <div>
                                    <strong>Operaatori</strong> ei voi muokata tietoja, mutta voi rekisteröidä
                                    tapahtumia työpisteellä.
                                  </div>
                                </Alert>
                                <FormControl
                                  as="select"
                                  value={newAccount.role}
                                  onChange={(e: any) => {
                                    setNewAccount({ ...newAccount, role: e.target.value })
                                  }}
                                >
                                  <option value="companyAdmin">Pääkäyttäjä</option>
                                  <option value="operator">Operaattori</option>
                                </FormControl>
                              </Form.Group>
                            </>
                          )
                        }}
                      </GoodForm>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        value="submit"
                        disabled={progress === Async.Progress.Progressing}
                        onClick={() => {
                          const userToUpsert = newAccount.id
                            ? (_.pick(newAccount, ["id", "name", "phone", "email", "role"]) as UpdateUser)
                            : (_.pick(newAccount, ["name", "phone", "email", "password", "role"]) as CreateUser)
                          doOperation(upsertUser(context, { ...userToUpsert, phone: userToUpsert.phone || undefined }))
                        }}
                      >
                        Tallenna
                      </Button>
                    </Modal.Footer>
                  </>
                )
              }}
            </Variable>
          </div>
        </Modal>
      )}
    </Operation>
  )
}

const UsersLists: React.FC = () => {
  const context = useContext(SessionContext)
  const [showModalWithUser, setShowModal] = useState(false as false | User | "new")
  if (context.role !== "companyAdmin") return <></>
  return (
    <div className="d-flex shadow-sm bg-light border rounded p-4 flex-column">
      <Variable initialValue={getAllUsers(context)}>
        {(users, setUsers) => (
          <Operation
            onDone={() => {
              setUsers(getAllUsers(context))
            }}
          >
            {(doOperation, progress) => (
              <>
                {showModalWithUser && (
                  <AddUserModal
                    user={showModalWithUser === "new" ? undefined : showModalWithUser}
                    context={context}
                    onHide={(userOrNull) => {
                      if (userOrNull) {
                        setUsers(getAllUsers(context))
                      }
                      setShowModal(false)
                    }}
                  />
                )}
                <div className="mb-3 d-flex justify-content-between">
                  <h4>Käyttäjät</h4>
                  <>
                    <Button
                      variant="light"
                      disabled={progress === Async.Progress.Progressing}
                      onClick={() => setShowModal("new")}
                    >
                      <FontAwesomeIcon icon={faPlus} className="me-2" size="sm" />
                      Lisää käyttäjä
                    </Button>
                  </>
                </div>
                <ListGroup className="d-flex">
                  {users
                    .filter((u) => !u.deletedAt)
                    .map((user) => (
                      <ListGroupItem className="d-flex justify-content-between align-items-center">
                        <div>
                          {user.role === "companyAdmin" && (
                            <FontAwesomeIcon size={"xs"} className="text-muted me-2" icon={faCrown} />
                          )}
                          {user.name}
                        </div>
                        <div>
                          <Button
                            disabled={progress === Async.Progress.Progressing}
                            size="sm"
                            className="me-1"
                            variant="primary"
                            onClick={() => setShowModal(user)}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                          <Button
                            disabled={progress === Async.Progress.Progressing}
                            size="sm"
                            variant="danger"
                            onClick={() => {
                              doOperation(
                                removeUser(user.id).do(
                                  () => {
                                    toast("Käyttäjä poistettu", "success")
                                  },
                                  () => {
                                    toast("Poisto epäonnistui", "danger")
                                  }
                                )
                              )
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </div>
                      </ListGroupItem>
                    ))}
                </ListGroup>
              </>
            )}
          </Operation>
        )}
      </Variable>
    </div>
  )
}

export default UsersLists
