import { Form } from "react-bootstrap"

export const FormCheck: React.FC<{ className?: string; onClick: () => void; checked: boolean; label: string }> = ({
  className,
  onClick,
  checked,
  label,
}) => {
  return (
    <div className={className} onClick={onClick} style={{ cursor: "pointer" }}>
      <Form.Check style={{ cursor: "pointer" }} readOnly checked={checked} label={label}></Form.Check>
    </div>
  )
}
