import React from "react"
import MobileUtilityPageWrapper from "../../page_wrappers/MobileUtilityPageWrapper"
import Session from "../../types/Session"
import Register from "../../views/Register"

interface RegisterPageProps {
  onRegister: (session: Session) => void
}

const RegisterPage: React.FC<RegisterPageProps> = ({ onRegister }) => {
  return (
    <MobileUtilityPageWrapper title={"Rekisteröidy"} footer={<></>}>
      <Register onRegister={onRegister} />
    </MobileUtilityPageWrapper>
  )
}

export default RegisterPage
