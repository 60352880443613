import Session from "../types/Session"
import { User } from "../types/User"
import { GET, POST, PUT } from "../utility/serviceUtility"

export interface NewAccount {
  companyName: string
  firstName: string
  lastName: string
  phone: string
  email: string
  password: string
  confirmPassword?: string
}

export function registerNewUser(newAccount: NewAccount) {
  return POST<NewAccount>("/users/register", newAccount)
}

export function getUserById(id: string) {
  return GET<User>(`/users/${id}`)
}

export function getAllUsers(user: Session) {
  return GET<User[]>(`/users/company/${user.companyId}`)
}

export interface UpdateUser {
  id: string
  name: string
  phone?: string
  email?: string
}

export interface CreateUser {
  name: string
  phone?: string
  email: string
  password: string
}

export function upsertUser(context: Session, user: UpdateUser | CreateUser) {
  return POST<User>(`/users/company/${context.companyId}`, user as User)
}

export function removeUser(userId: string) {
  return PUT(`/users/${userId}/delete`, {})
}
