import { Async } from "react-declarative-state"

type Query = Record<string, any> | undefined

declare const document: Document

const root = (() => {
  if (document.location.host.includes("localhost")) return "http://localhost:8000"
  if (document.location.host.includes("cloudfront.net")) return `${document.location.origin}/api`
  if (document.location.host.includes("huolla.com")) return `${document.location.origin}/api`
  throw new Error("serviceUtility: unknown host")
})()

function formatQuery(query: Query) {
  return query ? `?${new URLSearchParams(query).toString()}` : ""
}

function formatUrl(path: string, query: Query) {
  return `${root}${path}${formatQuery(query)}`
}

const getHeaders = () => ({
  authentication: `Bearer ${localStorage.getItem("sessionToken")}`,
  Accept: "application/json",
  "cache-control": "max-age=0, must-revalidate",
})

export function GET<T>(path: string, query?: Query) {
  return Async.GET<T>(formatUrl(path, query), getHeaders())
}

export function POST<T>(path: string, data: T, query?: Record<string, any>) {
  return Async.POST<T>(formatUrl(path, query), data, {
    ...getHeaders(),
    Accept: "application/json",
    "Content-Type": "application/json",
  }).catch((err) => {
    throw err.message
  })
}

export function PUT<T>(path: string, data: T, query?: Record<string, any>) {
  return Async.PUT<T>(formatUrl(path, query), data, {
    ...getHeaders(),
    Accept: "application/json",
    "Content-Type": "application/json",
  }).catch((err) => {
    throw err.message
  })
}
