import { RouterProvider, createBrowserRouter } from "react-router-dom"
import ErrorPage from "./pages/ErrorPage"
import Root from "./page_wrappers/Root"
import LandingPage from "./pages/LandingPage"
import FullScreen from "./page_wrappers/FullScreen"
import LoginPage from "./pages/mobile/LoginPage"
import RegisterPage from "./pages/mobile/RegisterPage"
import RequestPasswordLinkPage from "./pages/mobile/RequestPasswordLinkPage"
import { SessionContext } from "./types/Session"
import { checkSession } from "./services/sessionService"
import { Variable } from "react-declarative-state"
import ProfilePage from "./pages/ProfilePage/ProfilePage"
import TimestampFixer from "./pages/TimestampFixer/TimestampFixer"

export default function App() {
  return (
    <Variable initialValue={checkSession()}>
      {(session, setSession) => {
        const router = createBrowserRouter([
          {
            path: "/",
            errorElement: <ErrorPage />,
            element: <Root />,
            children: [
              {
                path: "/",
                element: <LandingPage />,
              },
              {
                path: "data",
                element: <TimestampFixer />,
              },
              {
                path: "profile",
                element: (
                  <ProfilePage
                    onLogout={() => {
                      setSession({ token: "", userId: "", name: "", companyId: "", role: "operator" })
                    }}
                  />
                ),
              },
            ],
          },
          {
            path: "app/",
            errorElement: <ErrorPage />,
            element: <FullScreen />,
            children: [
              {
                path: "login",
                element: <LoginPage onLogin={setSession} />,
              },
              {
                path: "register",
                element: <RegisterPage onRegister={setSession} />,
              },
              {
                path: "request-password",
                element: <RequestPasswordLinkPage />,
              },
            ],
          },
        ])
        return (
          <SessionContext.Provider value={session}>
            <RouterProvider router={router} />
          </SessionContext.Provider>
        )
      }}
    </Variable>
  )
}
