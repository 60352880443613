import { Observable } from "rxjs"
import Session from "../types/Session"
import { GET, POST } from "../utility/serviceUtility"

export function checkSession() {
  return GET<Session>("/session").catch(() => {
    return Observable.of({ token: "", userId: "", name: "", companyId: "", role: "operator" } as Session)
  })
}

export function login(credentials: { email: string; password: string }) {
  return POST<{ email: string; password: string }>("/session/login", credentials).do((session) => {
    localStorage.setItem("sessionToken", session.token)
  })
}

export function logout() {
  return POST<{}>("/session/logout", {}).do(() => {
    localStorage.setItem("sessionToken", "")
  })
}
