import React, { useContext } from "react"
import { Async, Operation, Variable } from "react-declarative-state"
import { createReportLink, getReports, voidReportLinkById } from "../../services/reportService"
import { Button, ListGroup, ListGroupItem } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy, faPlus } from "@fortawesome/free-solid-svg-icons"
import { toast } from "../../portals/toast"
import { SessionContext } from "../../types/Session"
import moment from "moment"
import { copyToClipboard } from "../../utility/uiTools"
import { orderBy } from "lodash"

const ReportsList: React.FC = () => {
  const context = useContext(SessionContext)
  return (
    <Variable initialValue={getReports(context)}>
      {(reports, setReports) => (
        <div className="d-flex shadow-sm bg-light border rounded p-4 mt-4 mb-4 flex-column">
          <div className="d-flex justify-content-between align-items-center">
            <h5>Raporttilinkit</h5>
            <Operation onDone={() => setReports(getReports(context))}>
              {(doOperation, progress) => (
                <Button
                  disabled={progress === Async.Progress.Progressing}
                  variant="light"
                  onClick={() =>
                    doOperation(
                      createReportLink().do(
                        () => {},
                        () => {
                          toast("Raportin luonti epännistui", "danger")
                        }
                      )
                    )
                  }
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" size="sm" />
                  Luo raporttilinkki
                </Button>
              )}
            </Operation>
          </div>
          <ListGroup className="mt-3">
            {orderBy(reports, ["voidedAt", "createdAt"], "desc").map((reportLink) => (
              <ListGroupItem className="d-flex flex-column p-3">
                {!reportLink.voidedAt && (
                  <div className="mb-1">
                    {moment(reportLink.expiresAt).isAfter(moment()) ? (
                      <div className="text-muted small mb-2">
                        Vanhenee: {moment(reportLink.expiresAt).format("DD.MM.YYYY HH:mm")}
                      </div>
                    ) : (
                      <div className="text-muted small mb-2">
                        Vanhentunut: {moment(reportLink.expiresAt).format("DD.MM.YYYY HH:mm")}
                      </div>
                    )}
                  </div>
                )}
                {!!reportLink.voidedAt && (
                  <div className="text-muted small mb-2">
                    Poistettu käytöstä: {moment(reportLink.voidedAt).format("DD.MM.YYYY HH:mm")}
                  </div>
                )}
                <div
                  className="d-flex justify-content-between align-items-center p-2 bg-light border rounded mb-1"
                  style={(!!reportLink.voidedAt && { textDecoration: "line-through" }) || undefined}
                >
                  {reportLink.url}
                  {!reportLink.voidedAt && (
                    <Button
                      variant="light"
                      size="sm"
                      onClick={() => {
                        copyToClipboard(reportLink.url)
                        toast("Kopioitu leikepöydälle", "primary")
                      }}
                    >
                      <FontAwesomeIcon icon={faCopy} />
                    </Button>
                  )}
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center mt-2">
                  <div className="text-muted small">Käytetty {reportLink.downloads} kertaa</div>
                  <Operation
                    onDone={() => {
                      setReports(getReports(context))
                    }}
                  >
                    {(doOperation, progress) => (
                      <div>
                        <Button
                          onClick={() => {
                            doOperation(
                              voidReportLinkById(reportLink.id).do(
                                () => {
                                  toast("Linkki poistettu käytöstä", "success")
                                },
                                () => {
                                  toast("Käytöstä poisto epäonnistui", "danger")
                                }
                              )
                            )
                          }}
                          disabled={progress === Async.Progress.Progressing || !!reportLink.voidedAt}
                          variant="link"
                          size="sm"
                        >
                          Poista käytöstä
                        </Button>
                      </div>
                    )}
                  </Operation>
                </div>
              </ListGroupItem>
            ))}
          </ListGroup>
        </div>
      )}
    </Variable>
  )
}

export default ReportsList
