import React, { useContext } from "react"
import { Button, Card, Col, Container, Row } from "react-bootstrap"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { SessionContext } from "../types/Session"
import WorkstationPage from "./WorkstationPage"

const nonLoggedInContent = (navigate: NavigateFunction) => (
  <Row>
    <Col sm={12} md={{ span: 6, offset: 3 }}>
      <Card className="mt-4 shadow">
        <Card.Body>
          <div className="d-flex justify-content-center my-3">
            <h5>Aloitus</h5>
          </div>
          <div className="d-flex mb-3 justify-content-center">
            <Button
              className="form-control"
              onClick={() => {
                navigate("/app/login")
              }}
            >
              Kirjaudu
            </Button>
          </div>
          <div className="d-flex mb-3 justify-content-center">
            <Button
              className="form-control"
              variant="outline-primary"
              onClick={() => {
                navigate("/app/register")
              }}
            >
              Ei vielä yritystiliä?
            </Button>
          </div>
          <div className="small text-muted mt-3 text-center d-flex">© 2023 Cadio Oy, All rights reserved</div>
        </Card.Body>
      </Card>
    </Col>
  </Row>
)

const LandingPage: React.FC = () => {
  const session = useContext(SessionContext)
  const navigate = useNavigate()
  return (
    <Container>
      <div className="d-flex flex-column">
        {!!session.userId ? (
          <>
            <WorkstationPage />
          </>
        ) : (
          nonLoggedInContent(navigate)
        )}
      </div>
    </Container>
  )
}

export default LandingPage
