import React from "react"
import { useRouteError } from "react-router-dom"

const ErrorPage: React.FC = () => {
  const error: any = useRouteError()
  return (
    <div>
      <h1>Oops!</h1>
      Something went wrong
      <h1>{error?.status}</h1>
    </div>
  )
}

export default ErrorPage
