import React from "react"
import { Form as GoodForm } from "react-good-form"
import { Operation, Variable } from "react-declarative-state"
import { FormGroup, Form, Button, Container, Row, Col } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { login } from "../services/sessionService"
import Session from "../types/Session"
import { toast } from "../portals/toast"

// TODO: Implement full screen mobile layout component

const Login: React.FC<{ onLogin: (value: Session) => void }> = ({ onLogin }) => {
  const navigate = useNavigate()
  return (
    <Operation
      onDone={() => {
        console.log("Logged in")
        navigate("/")
      }}
    >
      {(doOperation) => (
        <Variable initialValue={{ email: "", password: "" } as { email: string; password: string }}>
          {(credentials, setCredentials) => (
            <GoodForm value={credentials} onChange={setCredentials}>
              {({ Input }) => {
                const doLogin = () =>
                  doOperation(
                    login(credentials).do(
                      (session) => {
                        onLogin(session)
                      },
                      () => {
                        toast(
                          "Väärä sähköpostiosoite tai salasana. Tarkista kirjautumistietosi ja yritä uudelleen",
                          "primary"
                        )
                      }
                    )
                  )
                return (
                  <Container>
                    <Row>
                      <Col xs={12} md={{ span: 6, offset: 3 }}>
                        <div
                          className="d-flex flex-column"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault()
                              doLogin()
                            }
                          }}
                        >
                          <FormGroup className="mb-3">
                            <Form.Label>Sähköposti</Form.Label>
                            <Input type="email" for="email" required className="form-control"></Input>
                          </FormGroup>
                          <FormGroup className="mb-4">
                            <Form.Label>Salasana</Form.Label>
                            <Input type="password" for="password" required className="form-control"></Input>
                          </FormGroup>
                          <div className="mb-2">
                            <Button className="form-control" onClick={doLogin} variant="primary">
                              Kirjaudu
                            </Button>
                          </div>
                          <div className="d-flex text-center mb-4">
                            <Button
                              className="form-control"
                              variant="link"
                              onClick={() => {
                                navigate("/app/request-password")
                              }}
                            >
                              Unohditko salasanasi?
                            </Button>
                          </div>
                          <div className="d-flex border-bottom border mb-4"></div>
                          <div className="text-muted text-center mb-3">Eikö sinulla ole vielä tunnuksia?</div>
                          <div className="mb-5">
                            <Button
                              className="form-control"
                              type="submit"
                              variant="outline-primary"
                              onClick={() => {
                                navigate("/app/register")
                              }}
                            >
                              Rekisteröidy
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                )
              }}
            </GoodForm>
          )}
        </Variable>
      )}
    </Operation>
  )
}

export default Login
