import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Toast, ToastProps } from "react-bootstrap"
import { createRoot } from "react-dom/client"

const ToastElement: React.FC<{ text: string; variant: ToastProps["bg"] }> = ({ text, variant }) => (
  <Toast className="ms-auto ms-auto me-2 mt-2 text-white" bg={variant}>
    <Toast.Body>
      <div className="d-flex justify-content-start align-items-center">
        <FontAwesomeIcon icon={faInfoCircle} size="xl" className="me-2" />
        {text}
      </div>
    </Toast.Body>
  </Toast>
)

export async function toast(text: string, variant: ToastProps["bg"]) {
  const domNode = document.getElementById("toast-root")!
  const root = createRoot(domNode)
  root.render(<ToastElement text={text} variant={variant || "primary"} />)
  setTimeout(() => {
    root.unmount()
  }, 2200)
}
