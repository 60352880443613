import React from "react"
import { User } from "./User"
export default interface Session {
  token: string
  userId: string
  name: string
  companyId: string
  role: User["role"]
}

export const SessionContext = React.createContext<Session>({
  token: "",
  userId: "",
  name: "",
  companyId: "",
  role: "operator",
})
