import { Outlet, useNavigate } from "react-router-dom"
import { Navbar, Container, Nav } from "react-bootstrap"
import { useContext } from "react"
import { SessionContext } from "../types/Session"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faIndustry, faUser, faWrench } from "@fortawesome/free-solid-svg-icons"

const Root: React.FC = () => {
  const session = useContext(SessionContext)
  const navigate = useNavigate()
  return (
    <div style={{ minHeight: "100vh" }}>
      <Navbar bg="light" fixed="top">
        <Container>
          <Navbar.Brand
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault()
              navigate("/")
            }}
          >
            {session.token ? (
              <div className="d-flex align-items-center py-1 px-3 btn btn-light border rounded">
                <FontAwesomeIcon icon={faIndustry} className="me-2" />
                logi
              </div>
            ) : (
              <div className="text-muted">
                <strong>huolla.com</strong>
              </div>
            )}
          </Navbar.Brand>
          {session?.token ? (
            <Nav className="ms-auto">
              <Nav.Link
                onClick={(e) => {
                  e.preventDefault()
                  navigate("/data")
                }}
              >
                <FontAwesomeIcon icon={faWrench} />
              </Nav.Link>
              <Nav.Link
                onClick={(e) => {
                  e.preventDefault()
                  navigate("/profile")
                }}
              >
                <FontAwesomeIcon icon={faUser} />
              </Nav.Link>
            </Nav>
          ) : (
            <Nav className="ms-auto">
              <Nav.Link href="/app/login">Kirjaudu</Nav.Link>
            </Nav>
          )}
        </Container>
      </Navbar>
      <Container style={{ marginTop: 60 }}>
        <Outlet />
      </Container>
    </div>
  )
}

export default Root
