import React, { ReactElement } from "react"
import { ChevronLeft } from "feather-icons-react"
import { useNavigate } from "react-router-dom"

interface MobileUtilityPageWrapperProps {
  title: string
  footer: ReactElement
  children: React.ReactNode
}

const MobileUtilityPageWrapper: React.FC<MobileUtilityPageWrapperProps> = ({ title, children, footer }) => {
  const navigate = useNavigate()
  const mobileHeaderHeight = 57
  return (
    <div style={{ minHeight: "100%" }} className="d-flex flex-column flex-grow-1 mobile-page-fade-in">
      <div className="bg-white" style={{ position: "fixed", width: "100%" }}>
        <div
          style={{ minHeight: mobileHeaderHeight }}
          className="mobile-page-title py-3 d-flex justify-content-center align-items-center border-bottom"
        >
          <div
            className="px-3 py-3"
            style={{ position: "absolute", left: 0, cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <ChevronLeft />
          </div>
          <div>{title}</div>
          <div></div>
        </div>
      </div>
      <div className="flex-grow-1 pt-3" style={{ marginTop: mobileHeaderHeight }}>
        {children}
      </div>
      <div className="mb-5">{footer}</div>
    </div>
  )
}

export default MobileUtilityPageWrapper
