import React from "react"
import { Outlet } from "react-router-dom"

const FullScreen: React.FC = () => (
  <div style={{ minHeight: "100vh" }}>
    <Outlet></Outlet>
  </div>
)

export default FullScreen
