import { Badge, BadgeProps, Button, FormCheck, FormControl, Table } from "react-bootstrap"
import { WorkstationTimestamp } from "../types/WorkstationTimestamp"
import { Fragment, ReactElement, useContext } from "react"
import { Async, Operation, Variable } from "react-declarative-state"
import moment from "moment"
import _, { truncate } from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEdit,
  faTrash,
  faSave,
  faCancel,
  faRecycle,
  faCode,
  faList,
  faHashtag,
  faWrench,
} from "@fortawesome/free-solid-svg-icons"
import { Workstation } from "../types/Workstation"
import { toast } from "../portals/toast"
import { editWorkstationTimeStamp, voidWorkstationTimeStamp } from "../services/workStationService"
import { SessionContext } from "../types/Session"

const IconWrapper: React.FC<{ children: ReactElement; bg?: BadgeProps["bg"]; size: number }> = ({
  children,
  bg,
  size,
}) => {
  return (
    <div
      style={{ width: size, height: size, minWidth: size, minHeight: size }}
      className={`d-flex bg-${bg || "secondary"} rounded-circle justify-content-center align-items-center me-2`}
    >
      {children}
    </div>
  )
}

const TimestampsTable: React.FC<{
  timestamps: WorkstationTimestamp[]
  setTimestamps: (timestamps: WorkstationTimestamp[]) => void
  selectedWorkStation: Workstation
}> = ({ timestamps, selectedWorkStation, setTimestamps }) => {
  const context = useContext(SessionContext)
  return (
    <Table className="mb-5" responsive>
      <tbody>
        {_.orderBy(timestamps, (t) => t.createdAt, "desc").map(
          ({
            id,
            batchNumber,
            toolNumber,
            batchSize,
            createdAt,
            voidedAt,
            isReWork,
            customField0,
            customField1,
            program,
          }) => (
            <Fragment key={id}>
              <Variable
                initialValue={{
                  id,
                  batchNumber,
                  toolNumber,
                  batchSize,
                  isReWork,
                  createdAt,
                  editing: false,
                  customField0,
                  customField1,
                  program,
                }}
              >
                {(editable, setEditable) => (
                  <>
                    <tr>
                      <td className="small text-muted pb-0" style={{ borderBottom: "none" }} colSpan={5}>
                        <div className="d-inline" style={voidedAt ? { textDecoration: "line-through" } : {}}>
                          {moment(createdAt).format("llll")}
                        </div>
                        {!!voidedAt && (
                          <Badge className="ms-2" bg="secondary">
                            Mitätöity
                          </Badge>
                        )}
                      </td>
                    </tr>
                    <tr className={voidedAt ? "text-muted" : undefined}>
                      <td style={{ borderBottom: "none" }}>
                        <div className="d-flex align-items-center">
                          <IconWrapper size={20}>
                            <FontAwesomeIcon style={{ fontSize: 12, color: "white" }} icon={faHashtag} />
                          </IconWrapper>
                          {editable.editing ? (
                            <FormControl
                              value={editable.batchNumber}
                              onChange={(e) => setEditable({ ...editable, batchNumber: e.target.value })}
                            ></FormControl>
                          ) : (
                            truncate(batchNumber)
                          )}
                        </div>
                      </td>
                      <td style={{ borderBottom: "none" }}>
                        <div className="d-flex align-items-center">
                          <IconWrapper size={20}>
                            <FontAwesomeIcon style={{ fontSize: 12, color: "white" }} icon={faWrench} />
                          </IconWrapper>
                          {editable.editing ? (
                            <FormControl
                              value={editable.toolNumber}
                              onChange={(e) => setEditable({ ...editable, toolNumber: e.target.value })}
                            ></FormControl>
                          ) : (
                            truncate(toolNumber) || "-"
                          )}
                        </div>
                      </td>
                      <td style={{ borderBottom: "none" }}>
                        <div className="d-flex align-items-center">
                          <IconWrapper size={20}>
                            <FontAwesomeIcon icon={faList} fontSize={12} color="white" />
                          </IconWrapper>
                          {editable.editing ? (
                            <FormControl
                              value={editable.batchSize}
                              type="number"
                              onChange={(e) =>
                                setEditable({
                                  ...editable,
                                  batchSize: parseInt(e.target.value, 10),
                                })
                              }
                            ></FormControl>
                          ) : (
                            <>{batchSize} kpl</>
                          )}
                        </div>
                      </td>
                      <td style={{ borderBottom: "none" }}>
                        {editable.editing ? (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => setEditable({ ...editable, isReWork: !editable.isReWork })}
                            className="p-2"
                          >
                            <FormCheck checked={editable.isReWork} label="Korjaustyö" />
                          </div>
                        ) : (
                          <>
                            {isReWork ? (
                              <Badge bg="warning">
                                <FontAwesomeIcon className="me-2" icon={faRecycle} />
                                korjaustyö
                              </Badge>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </td>
                      <td
                        rowSpan={2}
                        style={{
                          verticalAlign: "middle",
                          textAlign: "right",
                          minWidth: 180,
                          width: 180,
                        }}
                      >
                        <Operation
                          onDone={(updated: WorkstationTimestamp) => {
                            toast("Merkintä päivitetty", "success")
                            setTimestamps(timestamps.map((t) => (t.id === updated.id ? updated : t)))
                          }}
                        >
                          {(doRowCorrectionOperation, progress) => (
                            <>
                              {editable.editing ? (
                                <>
                                  <Button
                                    disabled={progress === Async.Progress.Progressing}
                                    size="sm"
                                    className="me-2"
                                    variant="danger"
                                    title="Mitätöi rivi"
                                    onClick={() => {
                                      doRowCorrectionOperation(
                                        voidWorkstationTimeStamp(context, editable.id).do(
                                          () => {
                                            toast("Tapahtuma mitätöity", "success")
                                            setEditable({ ...editable, editing: false })
                                          },
                                          () => {
                                            toast("Tapahtuman mitätöinti epäonnistui", "danger")
                                          }
                                        )
                                      )
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                  <Button
                                    size="sm"
                                    disabled={progress === Async.Progress.Progressing}
                                    className="me-2"
                                    onClick={() => {
                                      if (
                                        !_.isEqual(
                                          {
                                            batchNumber,
                                            toolNumber,
                                            batchSize,
                                            isReWork,
                                            customField0,
                                            customField1,
                                            program,
                                          },
                                          _.pick(editable, [
                                            "batchNumber",
                                            "toolNumber",
                                            "batchSize",
                                            "isReWork",
                                            "customField0",
                                            "customField1",
                                            "program",
                                          ])
                                        )
                                      ) {
                                        doRowCorrectionOperation(
                                          editWorkstationTimeStamp(context, editable.id, {
                                            batchNumber: editable.batchNumber,
                                            toolNumber: editable.toolNumber,
                                            batchSize: editable.batchSize,
                                            isReWork: editable.isReWork,
                                            customField0: editable.customField0,
                                            customField1: editable.customField1,
                                            program: editable.program,
                                          }).do(
                                            () => {
                                              setEditable({ ...editable, editing: false })
                                            },
                                            () => {
                                              toast("Muokkaus epäonnistui. Yritä myöhemmin uudelleen", "danger")
                                            }
                                          )
                                        )
                                      }
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faSave} />
                                  </Button>
                                  <Button
                                    disabled={progress === Async.Progress.Progressing}
                                    size="sm"
                                    variant="secondary"
                                    onClick={() => setEditable({ ...editable, editing: false })}
                                  >
                                    <FontAwesomeIcon icon={faCancel} />
                                  </Button>
                                </>
                              ) : (
                                <Button
                                  disabled={!!voidedAt || progress === Async.Progress.Progressing}
                                  size="sm"
                                  onClick={() => {
                                    setEditable({ ...editable, editing: true })
                                  }}
                                >
                                  <FontAwesomeIcon icon={faEdit} />
                                </Button>
                              )}
                            </>
                          )}
                        </Operation>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {selectedWorkStation.usesPrograms && (
                          <div className="d-flex align-items-center">
                            <IconWrapper size={20}>
                              <FontAwesomeIcon icon={faCode} color="white" fontSize={10} />
                            </IconWrapper>
                            {editable.editing ? (
                              <FormControl
                                value={editable.program || ""}
                                type="text"
                                onChange={(e) =>
                                  setEditable({
                                    ...editable,
                                    program: e.target.value,
                                  })
                                }
                              ></FormControl>
                            ) : (
                              program
                            )}
                          </div>
                        )}
                      </td>
                      <td>
                        {selectedWorkStation.customField0Name && (
                          <div className="d-flex align-items-center">
                            <IconWrapper size={20}>
                              <div style={{ fontSize: 12, color: "white", fontWeight: "bold" }}>1</div>
                            </IconWrapper>
                            {editable.editing ? (
                              <FormControl
                                value={editable.customField0 || ""}
                                type={selectedWorkStation.customField0Type || "text"}
                                onChange={(e) =>
                                  setEditable({
                                    ...editable,
                                    customField0: e.target.value,
                                  })
                                }
                              ></FormControl>
                            ) : (
                              customField0
                            )}
                          </div>
                        )}
                      </td>
                      <td>
                        {selectedWorkStation.customField1Name && (
                          <div className="d-flex align-items-center">
                            <IconWrapper size={20}>
                              <div style={{ fontSize: 12, color: "white", fontWeight: "bold" }}>2</div>
                            </IconWrapper>
                            {editable.editing ? (
                              <FormControl
                                value={editable.customField1 || ""}
                                type={selectedWorkStation.customField1Type || "text"}
                                onChange={(e) =>
                                  setEditable({
                                    ...editable,
                                    customField1: e.target.value,
                                  })
                                }
                              ></FormControl>
                            ) : (
                              customField1
                            )}
                          </div>
                        )}
                      </td>
                      <td></td>
                    </tr>
                  </>
                )}
              </Variable>
            </Fragment>
          )
        )}
      </tbody>
    </Table>
  )
}

export default TimestampsTable
